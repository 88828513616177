<template>
    <div class="order-table">
        <div class="card">
            <div class="card-body">
                <div class="pb-3">
                    <h5 class="card-title mb-2">Orders List</h5>
                </div>
                <div class="row mb-2 gy-2">
                    <div class="col-sm-12 col-md-6 d-flex">
                        <div id="tickets-table_length" class="mr-2 dataTables_length">
                            <label class="d-inline-flex mb-0 align-items-center">
                                <b-form-select class="form-select" v-model="perPage" :options="pageOptions"></b-form-select>
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div class="form-inline text-sm-end">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <label class="d-inline-flex mb-0 align-items-center">
                                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control"></b-form-input>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-0">
                    <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class="" ref="selectableTable" 
                    :items="orders" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                        <template v-slot:cell(id)="data">
                            <span class="d-inline-block cursor-pointer align-middle">
                                <span @click="navigateTo(data.item.id)" class="mr-2 font-sm"> #{{data.item.number}}</span>
                            </span>
                        </template>
                        <template v-slot:cell(status)="data">
                            <span class="d-inline-block cursor-pointer align-middle">
                                <span @click="navigateTo(data.item.id)" class="mr-2 font-sm">{{ data.item.status }}</span>
                            </span>
                        </template>
                        <template v-slot:cell(amount)="data">
                           <span @click="navigateTo(data.item.id)"> ${{ data.item.amount | money_format }}</span>
                        </template>
                        <template v-slot:cell(delivery_time)="data">
                            <span style="min-width:100px;" class="d-inline-block cursor-pointer align-middle">
                                <span @click="navigateTo(data.item.id)" class="font-sm">{{ calculateDeliveryTime(data.item.delivery_time) }}</span>
                            </span>
                        </template>
                        <template v-slot:cell(date)="data">
                            <span style="min-width:80px;" class="d-inline-block cursor-pointer" @click="navigateTo(data.item.id)">{{data.item.created_at | date_ago}} </span>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded">
                                <!-- pagination -->
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'order-table',
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            selected: [],
            isSelectAll: false,
            columns: [
                {
                    key: "id",
                    label: "ID"
                },
                {
                    key: "status",
                    sortable: true
                },
                {
                    key: "amount",
                    label: "Amount",
                },
                {
                    key: "delivery_time",
                    label: "Delivery time",
                    sortable: true
                },
                {
                    key: "date",
                    label: "Date",
                    sortable: true
                },
            ]
            }
    },
    computed:{
        orders(){
            return this.$store.state.orderList.orders
        },
        rows() {
            return this.orders.length
        },
    },
    methods:{
        navigateTo(orderId){
            this.$router.push({path: `/orders/${orderId}`});
        },
        selectAllRows() {
            this.isSelectAll = !this.isSelectAll
            if(this.isSelectAll){
                this.selected = this.orders.map(o => o.id );
            }else{
                this.selected = []
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
    mounted() {
        this.totalRows = this.orders.length;
    },

}
</script>

<style scoped>

.table-orders tr{
    background: #fff;
    border-bottom: 10px solid #f7f8f9;
    border-top: 10px solid #f7f8f9;
}
.table-orders tr:hover{
    background: #f1f5f7;
    transform: all .5s ease-in-out;
    cursor: pointer;
}

.order-table .table td, .table th{
    padding: 1.5rem 1rem;
}

</style>
