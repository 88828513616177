<template>
    <div class="dashboard">
        <section class=" overflow-hidden">
            <div class="row g-5 mb-5">
                <div class="col-md-8">
                    <div class="card h-100 bg-soft-primary">
                        <div class="card-body  p-0 d-flex flex-fill">
                            <div class="row g-0 w-100">
                                <div class="col-6">
                                    <div class="text-primary p-3 m-1">
                                        <h4 class="text-primary">Welcome <br/> Back, {{activeUser.first_name}}</h4>
                                        <p class="mb-3">Brandboosta Dashboard</p>
                                        <div class="m-0">
                                            <router-link to="/account" class="btn btn-soft-primary btn-sm" 
                                            data-bs-target="#kt_modal_create_app" data-bs-toggle="modal">View Profile</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 align-self-end text-end">
                                    <img :src="absoluteUrl('/images/banners/customer-support.png')" 
                                    alt="Customer Support" class="img-fluid illustration-img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-none h-100"> 
                        <div class="card-body">
                            <div class="d-flex flex-column justify-content-between h-100">
                                <div class="pt-12">
                                    <div class="text-center mb-3">See your recent activities and notifications</div>
                                    <div class="text-center">
                                        <router-link to="/notifications" class="btn btn-primary btn-sm">Activities</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import OrderTable from "@/components/order/OrderTable.vue"

export default {
    name: 'dashboard',
    components:{
        OrderTable
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        }
    }
    
}
</script>

<style scoped>

</style>
